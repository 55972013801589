import cx from "classnames"
import styles from "./InfoModal.module.scss"
import infoIcon from "../../images/icons/icon-info.svg"
import ScrollContainer from "../ScrollContainer/ScrollContainer"
import Button from "../Button/Button"

function InfoModal({ isOpen, setIsOpen }) {
  return (
    <div className={cx(styles.InfoModal, { [styles.Open]: isOpen })}>
      <div className={styles.Inner}>
        <img className={styles.Icon} src={infoIcon} alt="Info" />

        <ScrollContainer>
          <p>
            Information and pricing given is indicative only. Please speak to a sales consultant for more information.
          </p>
          <p>
            According to Ofgem, the average British household has 2.4 people living in it and uses 2,900 kWh of electricity and 12,000 kWh of gas. This works out at 242 kWh of electricity and 1,000 kWh of gas per month.
          </p>

          <table>
            <thead>
              <tr>
                <th scope="col">Gas and electricity usage</th>
                <th scope="col">Average annual consumption</th>
                <th scope="col">Average annual cost*</th>
                <th scope="col">Average monthly cost*</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Low (flat or 1-bedroom house / 1-2 people)</td>
                <td>Gas: 8,000 kWh Elec: 1,800 kWh</td>
                <td>£1,712.25 </td>
                <td>£142.69</td>
              </tr>
              <tr>
                <td>Medium (3-bedroom house / 2-3 people)</td>
                <td>Gas: 12,000 kWh Elec: 2,900 kWh</td>
                <td>£2,499.87 </td>
                <td>£208.32</td>
              </tr>
              <tr>
                <td>High (5-bedroom house / 4-5 people)</td>
                <td>Gas: 17,000 kWh Elec: 4,300 kWh</td>
                <td>£3,492.90 </td>
                <td>£291.08</td>
              </tr>
            </tbody>
          </table>

          <p>
            Cost savings value shown is based on the same balance of 19.5% Electricity at 34.037p/KWh and 80.5% Gas at 10.330p/KWh. We have not included standing charges. The KWh cost values are from British Gas. The split of energy type is from Ofgem's "Typical Domestic Consumption Values 2020 Decision Letter".
          </p>
          <p>
            EPC data is provided by The Department for Levelling Up, Housing &amp; Communities via the Open Data Communities API. Licensed under the Open Government Licence v3.0.
          </p>
          <p>
            Our <b>Eco Electric</b> homes use Air Source Heat Pumps to heat your home, combined with improved insulation and our leading ‘Fabric-First’ specification. These homes provide you with the latest energy efficient technology.
          </p>
          <p>
            Our <b>Eco Now</b> homes use our highly efficient ‘Fabric-First’ approach to save you energy. From the way the home is laid out, the material we use to build it and the high-specification doors, windows and boilers we use, everything is designed to retain heat in the home
          </p>
        </ScrollContainer>

        <div className={styles.Button}>
          <Button
            text={"Close"}
            color={"Red"}
            onClick={() => setIsOpen(false)}
          />
        </div>
      </div>
    </div>
  )
}

export default InfoModal
